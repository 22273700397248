import { useQuery } from '@tanstack/react-query';
import { Alert, Button, Divider, Flex, Popconfirm, Segmented, Space, Spin } from 'antd';
import queries, {BRIEFING_CONTENT, BRIEFING_INFO, BRIEFING_INFO_ITEM} from '../../functions/Queries';
import { useEffect, useState } from 'react';
import { SegmentedOptions } from 'antd/es/segmented';
import TextArea from 'antd/es/input/TextArea';
import './questions.css'
import { set } from 'date-fns';

export default function InformationTab(props: {
  type: string,
  data?: BRIEFING_CONTENT,
  onSavedChanged?:(val:boolean)=>void
  onDataChanged?: (val:BRIEFING_CONTENT) => void
}) {
  const [selectedType, setSelectedType] = useState('0')
  const { isPending, isError, data: briefingData, error } = useQuery(queries.queries.BRIEFINGS(props.type))
  const [data, setData] = useState<BRIEFING_CONTENT|undefined>(undefined)
  const [changed, setChanged] = useState(false)
  const [typeToSelect, setTypeToSelect] = useState<string|undefined>(undefined)

  let briefingTypeSelector = (<Spin />)
  let questions = null;


  useEffect(() => {
    if(props.data)
      setData(JSON.parse(JSON.stringify(props.data)))
    return () => {
      setData(undefined)
      props.onSavedChanged?.(false)
    }
  },[])

  useEffect(() => {
    if(briefingData !== undefined)
      setSelectedType(props.data ? briefingData.findIndex((e) => e.name === props.data?.name).toString(): '0')
  },[props.data,briefingData])

  useEffect(() => {
    if(data === undefined){
      if(props.data)
        if(briefingData !== undefined)
        {
           if(props.data.name !== briefingData![parseInt(selectedType)].name)
           {
              setData({name: briefingData![parseInt(selectedType)].name, answers: briefingData![parseInt(selectedType)].questions.map((e) => ({question: e.id, answer: '', optional: !!e.optional}))})
           }
           else {
            setData(JSON.parse(JSON.stringify(props.data)))
           }
        }
        else {
          setData(JSON.parse(JSON.stringify(props.data)))
        }
      else if(briefingData !== undefined) {
        setData({name: briefingData![parseInt(selectedType)].name, answers: briefingData![parseInt(selectedType)].questions.map((e) => ({question: e.id, answer: '',optional: !!e.optional}))})
      }
    } 
  },[data,briefingData])

  const getDataValue = (id:string) => {
    if(data){
      const item = data.answers.find((e) => e.question === id)
      if(item){
        return item.answer
      }
    }
    return ''
  }

  const setDataValue = (id:string,value:string) => {
    if(data){
      const item = data.answers.find((e) => e.question === id)
      if(item){
        item.answer = value
        setData({...data})
        setChanged(true)
        props.onSavedChanged?.(true)
      }
    }
  }

  const handleChangeSelectType = (value: string) => {
    setTypeToSelect(value)
  }

  const confirmChangeType = () => {
    setSelectedType(typeToSelect!)
    setData(undefined)
    setTypeToSelect(undefined)
  }

  if(!isPending){
    const options: SegmentedOptions<string> = briefingData!.map((e, idx) => ({
          label: (
            <Flex className="create-text-text-style-button" align="center" gap={8} >
              <span >{e.title}</span>
            </Flex>
          ),
          value: idx.toString()
        }))
    briefingTypeSelector = (<>
      <Popconfirm
          title="Alterar tipo de briefing?"
          description="Alterar o tipo de briefing irá apagar todas as respostas já preenchidas. Deseja continuar?"
          open={typeToSelect !== undefined}
          onConfirm={()=>{setTypeToSelect(undefined)}}
          okText="Não"
          cancelText="Sim"
          onCancel={confirmChangeType}>
          <Segmented options={options} value={selectedType} onChange={handleChangeSelectType} />
      </Popconfirm>
      
    </>
    )

    const questionData = briefingData![parseInt(selectedType)].questions;
    questions = questionData.map((el:BRIEFING_INFO_ITEM,idx:number) => (
      <div key={el.id}>
          <Flex vertical className="create-text-section-item" >
            <span>{el.text}{el.optional ? '':'*'}</span>
            <TextArea rows={2} value={getDataValue(el.id)} onChange={(e)=>setDataValue(el.id,e.target.value)}/>
          </Flex>
          {idx < questionData.length - 1 && (<Divider />)}
      </div>
    ))
  }
  
  return (
  <Flex vertical align='center' gap="large">
    {briefingTypeSelector}  
    <Flex vertical className="create-text-section-panel" gap="small">
      {questions}
    </Flex>
    <Button key="close" disabled={!changed} style={{padding:'0.1rem 0.3rem 0.1rem 0.3rem',marginBottom:'0.3rem', marginRight:'0.3rem'}} onClick={()=>{props.onDataChanged?.(data!)}} >Salvar</Button>
  </Flex>)

}

