import { BRIEFING_CONTENT, TESTIMONY } from '../../functions/Queries';
import { Modal, Popconfirm, Tabs } from 'antd';
import Testimony from './Testimony';
import InformationTab from './InformationTab';
import { CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';

export default function Source(props:{
  onDataChanged:(val:BRIEFING_CONTENT)=>void,
  type:string,
  data?:BRIEFING_CONTENT,
  testimony?:TESTIMONY[],
  onTestimonyChanged:(val:TESTIMONY[])=>void,
  open:boolean,
  onClose:()=>void
}) {

  const [informationNeedsSave, setInformationNeedsSave] = useState(false)

  const doClose = () => {
    setInformationNeedsSave(false)
    props.onClose()
  }

  const onDataChanged = (val:BRIEFING_CONTENT) => {
    props.onDataChanged(val)
    doClose()
  }


  const depoimentos = (
    <div className="info-panel-content">
      <Testimony onDataChanged={props.onTestimonyChanged} data={props.testimony}/> 
    </div>
  )

  const tabItems = [{
    key: '1',
    label: 'INFORMAÇÕES',
    children: (<InformationTab type={props.type} data={props.data} onSavedChanged={setInformationNeedsSave} onDataChanged={onDataChanged}/>)
  },
  {
    key: '2',
    label: 'DEPOIMENTOS',
    children: depoimentos
  }]


  const closeIcon = informationNeedsSave? (
    <Popconfirm
    title="Deseja realmente sair sem salvar?"
    description="As informações não foram salvas. Deseja realmente sair sem salvar?"
    okText="Sim"
    cancelText="Não"
    onConfirm={props.onClose}
  >
    <CloseOutlined/>
  </Popconfirm>
  ) : <CloseOutlined onClick={doClose}/>

  return (
    props.open ?
    (<Modal open={props.open} closeIcon={closeIcon} className='source-modal' style={{ top: 20 }} width={1040} footer={null} >
      <div className="info-panel">
        <Tabs defaultActiveKey="1" items={tabItems}/>
      </div>
    </Modal>) : null
  )

}