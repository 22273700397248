import { Button, Collapse, Modal } from "antd";

export interface PromptDialogProps {
    open: boolean;
    onClose: () => void;
    prompts: { role: string, content: string }[]
}

export function PromptDialog(props: PromptDialogProps) {

    const handleClose = () => {
        props.onClose();
    };

    if(!props.prompts) {
        return null;
    }

    const doExport = (role:string) => {

        const text = props.prompts.filter(x => x.role === role).map((prompt) => prompt.content).join('\n');
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });

    }
    const collapseItems = props.prompts.map((prompt, idx) => {
      
        const children = (
            <div style={{width: '100%'}}>
                {prompt.content.split('\n').map((el, idx) => (
                    <p style={{marginBottom: '1.5em'}} key={idx}>
                        {el}
                    </p>
                ))}
            </div>            
        )

        return {key: idx, label: prompt.role, children}
    });

    return (
        <Modal onCancel={handleClose} open={props.open} title="Prompts" footer={<></>}>
            <Collapse items={collapseItems} />
            <Button style={{marginTop:'1rem'}} onClick={()=>doExport('system')}>Exportar System</Button>
            <Button style={{marginTop:'1rem'}} onClick={()=>doExport('user')}>Exportar User</Button>
        </Modal>
    );
}