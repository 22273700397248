import { Row,Col, Input, Button, Alert, Spin } from "antd";
import "./login.css";
import { LoginThemeProvider } from "../../components/ThemeProviders";
import { useEffect, useState } from "react";
import { ResetPasswordDialog } from "./ResetPasswordDialog";
import { doSignIn } from "../../functions/CognitoClient";
import { useNavigate } from "react-router-dom";
import queries from '../../functions/Queries';
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

function Login(){

    const [username, setUsername] = useState(process.env.REACT_APP_DEFAULT_USERNAME||'');
    const [password, setPassword] = useState(process.env.REACT_APP_DEFAULT_PASSWORD||'');
    const [resetPwdOpen,setResetPwdOpen] = useState(false);
    const [canReset,setCanReset]= useState(false);
    const [loginError, setLoginError] = useState(false);
    const { isPending, isError, data, error } = useQuery(queries.queries.MAINTENANCE)

    const navigate = useNavigate()
    
    useEffect(()=>{
        setCanReset(username.length > 0) 
    },[username])

    const resetPassword = async()=>{
        setResetPwdOpen(true)
    }

    const signInMessageWrapper = async()=>{
        
        if(loginError){
            setLoginError(false)
            setTimeout(()=>{
              signIn()
            },2000)
        } 
        else {
            signIn()
        }
            
    }

    const signIn = async () => {
        try {
          const loginSuccess = await doSignIn(username,password)
          if(loginSuccess)
            navigate("/")
          else {
            console.error('Falha login')
          }
        } catch (error) {
          setLoginError(true);
        }
    };

    let content = <Spin size="large" />

    if(!isPending){
       content = data && data.blockUntil ? (
            <Alert
            message={(<h2>Desculpe, estamos em manutenção</h2>)}

            description={data.blockUntil > new Date() ? `Previsão de retorno: ${format(data.blockUntil!, 'dd/MM HH:mm')}`: "Estamos trabalhando para retornar o mais rápido possível"}
            type="error"
          />
        ):
        (
            <>
                {loginError && <Alert message="Usuário ou senha inválidos" type="error" className="loginError"/>}
                <Input className="rightAreaInput" placeholder = "E-mail" style={{marginBottom:'1.5rem'}} onChange={(e) => setUsername(e.target.value)} value={username}/>
                <Input.Password className="rightAreaInput" placeholder = "Senha" style={{marginBottom:'1.5rem'}} onChange={(e) => setPassword(e.target.value)} value={password}/>
                <Button className="loginButton" onClick={signInMessageWrapper}>login</Button>
                <Button type="link" className="loginButton resetLink" disabled={!canReset} onClick={resetPassword}>Esqueci a senha {!canReset? '(Preencha o campo de email para iniciar)':''}</Button>
            </>
        )
    }

    return (
        <LoginThemeProvider>
            <Row>
                <Col className="leftArea" span={12}>
                    <ul>
                    <li>Sua IA especializada em textos de comunicação interna.</li>
                    <li>Treinada para conectar as visões da empresa e do colaborador.</li>
                    <li>E que possibilita que as próprias fontes do conteúdo gerem seus textos.</li>    
                    </ul>
                </Col>
                <Col className="rightArea" span={12}>
                    <img src='/logo.png' width='60%' style={{marginBottom:'3rem'}}/>
                    {content}
                </Col>
            </Row> 
            <ResetPasswordDialog open={resetPwdOpen} userName={username} onClose={()=>{setResetPwdOpen(false)}}/>
        </LoginThemeProvider>
    )

}

export default Login;