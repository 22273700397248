import env from "./env"
import { doFetch } from "./CognitoClient"

export type TEXT_INFO = {
    id:string,
    name:string,
    created:string,
    lastModified:string,
    type: string,
    creator?: string
}
export type TESTIMONY = {
    name: string,
    position: string,
    statement: string
  }

export type MAINTENANCE_INFO = {
    blockUntil?: Date 
}

export type BRIEFING_INFO_ITEM = {
    id: string,
    text: string,
    order: number,
    optional?: boolean
}

export type BRIEFING_INFO = {
    name: string,
    title: string,
    order: number,
    questions: BRIEFING_INFO_ITEM[] 
}

export type BRIEFING_CONTENT_ITEM = {
    question: string,
    answer: string,
    optional: boolean
}

export type BRIEFING_CONTENT = {
    name: string,
    answers: BRIEFING_CONTENT_ITEM[]
}

export type TEXT_CONTENT = {
    content:{
        title:string,
        content:string
    },
    info: {
        name : string,
        voice : string,
        style?: string,
        type: string,
        language?: string,
        data : BRIEFING_CONTENT,
        testimonies?: TESTIMONY[]
    },
    id: string,
    prompts?: {role:string,content:string}[]
}

type FUNCTION_CONFIG_ITEM = {
    method: "GET"|"PUT"|"POST"|"DELETE"
}

const FUNCTION_TYPES = {
    ALL_TEXTS : ({
        method: "GET"
    } as FUNCTION_CONFIG_ITEM),
    TEXT : ({
        method: "GET"
    } as FUNCTION_CONFIG_ITEM),
    UPDATE_TEXT_CONTENT : ({
        method: "PUT"
    } as FUNCTION_CONFIG_ITEM),
    UPDATE_TEXT_NAME : ({
        method: "PUT"
    } as FUNCTION_CONFIG_ITEM),
    CREATE_TEXT : ({
        method: "POST"
    } as FUNCTION_CONFIG_ITEM),
    DELETE_TEXT : ({
        method: "DELETE"
    } as FUNCTION_CONFIG_ITEM),
    BRIEFINGS : ({
        method: "GET"
    } as FUNCTION_CONFIG_ITEM),
    HELLO: ({
        method: "GET"
    }as FUNCTION_CONFIG_ITEM),
    MAINTENANCE: ({
        method: "GET"
    }as FUNCTION_CONFIG_ITEM)


}

async function getFetchOptions(o:FUNCTION_CONFIG_ITEM): Promise<RequestInit> {
    const headers = new Headers();
    if(o.method !== 'GET') {
        headers.append("Accept",'application/json')
        headers.append("Content-Type",'application/json')
    }

    if (process.env.REACT_APP_IS_BETA === 'true') {
        headers.append("X-use-beta", '1')
    }

    return {
        method:o.method,
        mode:'cors',
        headers: headers
    }        
}


async function fetchText(id:string): Promise<TEXT_CONTENT|undefined> {
    const options = await getFetchOptions(FUNCTION_TYPES.TEXT)
    var retries = 1;
    await delay(3000);
    while(true) {
        env.log.info(`tentativa ${retries} de obtenção do texto ${id}`)
        const response = await doFetch(`${env.NEXA_API}/text/${id}`, options);
        if(response.status==202){
            retries++
            await delay(3000);
        }
        else if(response.status==200) {
            const data = await response.json()
            env.log.info(`obtido ${id} após ${retries} tentativas`, response.status, data)
            return data
        }
        else {
            env.log.info(`erro na tentativa ${retries} do documento ${id}: ${response.status}`,response.statusText)
            return undefined
        }
    }
}

function delay(milliseconds: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}

export default {
    queries: {
        HELLO: {
            queryKey: ['HELLO'],
            queryFn: async (): Promise<any> => {
                const options = await getFetchOptions(FUNCTION_TYPES.HELLO)
                const response = await doFetch(`${env.NEXA_API}/hello`,options);
                const data = await response.json()
                return data
            }
        },
        ALL_TEXTS :{
            queryKey : ['ALL_TEXTS'],
            queryFn: async (): Promise<TEXT_INFO[]> => {
                try {
                    const options = await getFetchOptions(FUNCTION_TYPES.ALL_TEXTS)
                    const response = await doFetch(`${env.NEXA_API}/text`,options);
                    const data = await response.json()
                    return data
                }
                catch (e) {
                    console.error('erro', e)
                    return []
                }
            }
        },
        TEXT: (id:string,type:string) => ({
            queryKey: ['TEXT',type,id],
            queryFn: async (): Promise<TEXT_CONTENT|undefined> => {
                const data = await fetchText(id);
                return data
            }
        }),
        BRIEFINGS: (type:string) => {
            return ({
            queryKey : ['BRIEFINGS',type],
            queryFn: async (): Promise<BRIEFING_INFO[]> => {
                try {
                    const options = await getFetchOptions(FUNCTION_TYPES.BRIEFINGS)
                    const response = await doFetch(`${env.NEXA_API}/briefings/${type}`,options);
                    var data = await response.json() as BRIEFING_INFO[]
                    data = data.sort((a,b) => a.order - b.order)
                    data.forEach((e) => e.questions = e.questions.sort((a,b) => a.order - b.order))
                    return data
                }
                catch (e) {
                    return []
                }
            }
        })},
        MAINTENANCE: {
            queryKey : ['MAINTENANCE'],
            queryFn: async (): Promise<MAINTENANCE_INFO> => {
                try {
                    const options = await getFetchOptions(FUNCTION_TYPES.MAINTENANCE)
                    const response = await doFetch(`${env.NEXA_API}/maintenance`,options);
                    const data = await response.json() 
                    return {
                        blockUntil: data.blockUntil ? new Date(data.blockUntil) : undefined
                    }
                }
                catch (e) {
                    console.error('erro', e)
                    return {
                        blockUntil: undefined
                    }
                }
            }
        }
    },
    mutations: {
        UPDATE_TEXT_CONTENT: {
            mutationFn: async (data:{id:string, type:string, title:string, content:string}) => {
                const options = await getFetchOptions(FUNCTION_TYPES.UPDATE_TEXT_CONTENT)
                const result = await doFetch(`${env.NEXA_API}/text/${data.id}`,{
                    ...options,
                    body:JSON.stringify({title:data.title,content:data.content})
                })
                return result.status === 200;
            }

        },
        UPDATE_TEXT_NAME: {
            mutationFn: async (data:{id:string, name:string}) => {
                const options = await getFetchOptions(FUNCTION_TYPES.UPDATE_TEXT_NAME)
                const result = await doFetch(`${env.NEXA_API}/textInfoUpdate/${data.id}`,{
                    ...options,
                    body:JSON.stringify({name:data.name})
                })
                return result.status === 200;
            },
        },
        CREATE_TEXT: {
            mutationFn: async (data:{id?:string, data: {name:string, voice:string, style:string, type:string, language:string, data:BRIEFING_CONTENT,testimonies:TESTIMONY[]}}) => {
                const sufix = data.id ? `/${data.id}` : ''
                const options = await getFetchOptions(FUNCTION_TYPES.CREATE_TEXT)
                const result = await doFetch(`${env.NEXA_API}/text${sufix}`,{
                    ...options,
                    body:JSON.stringify(data.data)
                })
                if(result.status === 200) {
                    const res = await result.json()
                    const id = res.id as string
                    return await fetchText(id)
                }
                return undefined;
            }
        },
        DELETE_TEXT: {
            mutationFn: async (data:{id:string}) => {
                const options = await getFetchOptions(FUNCTION_TYPES.DELETE_TEXT)
                const result = await doFetch(`${env.NEXA_API}/text/${data.id}`,{
                    ...options,
                })
                return result.status === 200;
            }
        },
    }
}