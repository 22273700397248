import { Avatar, Divider, Dropdown, Flex, Layout, MenuProps } from 'antd';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { UserOutlined } from '@ant-design/icons';
import ActionButton from './components/ActionButton';
import { Outlet, useNavigate } from 'react-router-dom';
import { doSignOut } from './functions/CognitoClient';
import { useState } from 'react';
import HelloDisplay from './HelloDisplay';


function App() {
  const navigate = useNavigate()
  
  const [showHello,setShowHello] = useState(false)

  const navigateTo = () => {
      navigate("/app/dashboard")
  }

  const logout = async () => {
    await doSignOut()
    navigate('/')
  }

  const userMenu: MenuProps['items'] = [
    {
      label: <span className='menu-item' onClick={logout}>Logout</span>,
      key: '0'
    }
  ]

  if (process.env.REACT_APP_IS_LOCAL === 'true') {
    userMenu.push(
      {
        label: <span className='menu-item' onClick={()=>setShowHello(true)}>Hello World!</span>,
        key: '1'
      }
    )  
  }
  
  return (
      <Layout >
        <Header className='header'>
          <Flex justify="space-between" align="center" style={{width:'100%'}}>
            <img className='logo' src="/logo2.png" width="100%" height='100%' />
            <Flex align='center' >
              <ActionButton className="dashboard-button" onClick={navigateTo}>Dashboard</ActionButton>      
              <Dropdown menu={{items: userMenu}} trigger={['click']}>
                <Avatar className="userMenu" size={48} icon={<UserOutlined />}  />
              </Dropdown>
            </Flex>
          </Flex>
        </Header>
        <Content className='content'>
          <Outlet/>
          <HelloDisplay open={showHello} onClose={()=>setShowHello(false)}/>
        </Content>
        <Footer className="footer">
           <Divider />
            <span className='footer-text'>© Journo | Inteligência em Conteúdos para Comunicação Interna 2025</span>
        </Footer>
      </Layout>
    );
}

export default App;
