import { CognitoIdentityProviderClient, ConfirmForgotPasswordCommand, ConfirmForgotPasswordCommandInput, ForgotPasswordCommand, ForgotPasswordCommandInput, InitiateAuthCommand, InitiateAuthCommandInput } from "@aws-sdk/client-cognito-identity-provider";
import env from "./env";

const cognitoClient = new CognitoIdentityProviderClient({
    region: "sa-east-1",
  });

const clientId = "4sjqrgmsqkfliart3mgf9jtpun"

export async function doSignIn(username:string,password:string) {
    const params: InitiateAuthCommandInput = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: clientId,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };
    const command = new InitiateAuthCommand(params);
    const val = await cognitoClient.send(command);
    const { AuthenticationResult } = val;
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');

      const idToken = AuthenticationResult.IdToken || '';
      const tokenParts = idToken.split('.');
      const payload64 = tokenParts[1];
      const payloadStr = atob(payload64);
      const payload = JSON.parse(payloadStr);
      const tenant = payload['tenant'];
      sessionStorage.setItem("tenant", tenant || '');

      return true;
    } else {
      return false;
    }
  };

export function doSignOut(){
  sessionStorage.removeItem("idToken");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");

}

export async function doResetPassword(username:string){
  const input: ForgotPasswordCommandInput = { // ForgotPasswordRequest
    ClientId: clientId,
    Username: username, // required
  };

  const command = new ForgotPasswordCommand(input)
  const val = await cognitoClient.send(command)
  env.log.info(input,val)
  return val.CodeDeliveryDetails?.Destination;
}

export async function doConfirmPasswordReset(username:string, code:string, password:string): Promise<"CODE"|"OK"|"PASSWORD"> {
  const input: ConfirmForgotPasswordCommandInput = {
    ClientId: clientId,
    ConfirmationCode: code,
    Password: password,
    Username: username
  }

  const command = new ConfirmForgotPasswordCommand(input)
  try {
    const val = await cognitoClient.send(command)
    return "OK"
  }
  catch(e:any) {
    var errorType = e.name
    if(errorType === "CodeMismatchException")
      return "CODE"
    else
      return "PASSWORD"
  }
}

export function getProperty(property:string) {
  const idToken = sessionStorage.getItem("idToken")
  if(!idToken) return "";

  const tokenParts = idToken.split('.')
  const payload64 = tokenParts[1]
  const payloadStr = atob(payload64)
  const payload = JSON.parse(payloadStr)
  return payload[property]
}

export async function doFetch(input: RequestInfo | URL, init: RequestInit) : Promise<Response> {
      var reTried = false;
      
      while(true)
        {
            try {
              var headers = new Headers(init.headers); 
              if(sessionStorage.getItem("idToken")) {
                headers.append("Authorization", `Bearer ${sessionStorage.getItem("idToken")}`)
              }
              
              const retVal = await fetch(input,{...init,headers})
              return retVal
          }
          catch (e:any) {
            if(reTried)
                throw e;
            env.log.info('Attempt Refresh')
            reTried = true;
            await doRefresh()
          }
      }
  }

async function doRefresh() {
    const params: InitiateAuthCommandInput = {
        AuthFlow: "REFRESH_TOKEN_AUTH",
        ClientId: clientId,
        AuthParameters: {
          REFRESH_TOKEN: sessionStorage.getItem("refreshToken")!,
        },
      };
      try {
        const command = new InitiateAuthCommand(params);
        const val = await cognitoClient.send(command);
        const { AuthenticationResult } = val;
        if (AuthenticationResult) {
          sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
          sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
        }
      } catch (error) {
        console.error("Error signing in: ", error);
        throw error;
      }
}

