import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Queries, { BRIEFING_CONTENT, TESTIMONY, TEXT_CONTENT } from "../../functions/Queries";
import { GetTextType } from "../../functions/TextFunctions";
import { Button, Card, Flex, Input, Spin } from "antd";
import './createText.css';
import QuestionPopup from "../../components/QuestionPopup";
import Source from "./Source";
import TextStyle from "./TextStyle";
import Editor from "./Editor";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { PromptDialog } from "./PromptDialog";

export default function CreateText() {

  const {state} = useLocation();

  const [name,setName] = useState('')
  const [originalName,setOriginalName] = useState('')
  const [editName,setEditName] = useState(false)
  const [voice,setVoice] = useState('')
  const [style,setStyle] = useState('neutro')
  const [language,setLanguage] = useState('pt-BR')
  const [data,setData] = useState<BRIEFING_CONTENT|null>(null)
  const [testimonies,setTestimonies] = useState<TESTIMONY[]>([])
  const [createEnabled,setCreateEnabled] = useState(false)
  const [waiting,setWaiting] = useState(false)
  const [content,setContent] = useState<any>(undefined)
  const [id,setId] = useState<string>()
  const [type,setType] = useState<string>(state.type)
  const [prompt,setPrompt] = useState<any>()
  const [showPrompts,setShowPrompts] = useState(false)
  const [textType,setTextType] = useState(GetTextType(state.type))


  const [textTypeTourOpen,setTextTypeTourOpen] = useState(false)
  const [voiceTourOpen,setVoiceTourOpen] = useState(false)
  const [briefingOpen,setBriefingOpen] = useState(false)


  const queryClient = useQueryClient();
  
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event?.target.value)
  }

  const handleSetData = (value:BRIEFING_CONTENT) => {
    setData(value)
  }
  
  const assignData = (data:TEXT_CONTENT|undefined) =>{
    if(data){
      setName(data.info.name)
      setVoice(data.info.voice)
      setStyle(data.info.style || 'neutro')
      setData(data.info.data)
      setContent(data.content)
      setId(data.id)
      setType(data.info.type)
      setPrompt(data.prompts)
      setTestimonies(data.info.testimonies||[])
      setLanguage(data.info.language || 'pt-BR')
    }
  }
  
  const startEditName = () => {
    setOriginalName(name)
    setEditName(true)
    document.getElementById('nameEdit')?.focus();

  }

  const cancelEditName = () => {
    setName(originalName)
    setEditName(false)
  }

  const saveVersionMutation = useMutation(Queries.mutations.UPDATE_TEXT_NAME);

  const confirmEditName = () => {
    setEditName(false)
    setWaiting(true)
    saveVersionMutation.mutate({
      name: name,
      id: id!
    }, {
      onSuccess: async (data: boolean) => {
        await queryClient.invalidateQueries({ queryKey: ['ALL_TEXTS']}); 
        await queryClient.invalidateQueries({ queryKey:  ['TEXT',type,id]}); 
        setWaiting(false);
      },
      onError: (err) => {
        console.error(err)
        setWaiting(false);
      }
    });
  }

  useEffect(()=>{
    const nameIsValid = name !== ''
    const voiceIsValid = voice !== ''
    const dataIsValid = !!data && data?.answers.find((e) => e.answer === '' && e.optional === false ) === undefined
    setCreateEnabled(nameIsValid && voiceIsValid && dataIsValid)
  },[name,voice,data])

  useEffect(()=>{
    setTextType(GetTextType(type || state.type))
  },[type])

  const newTextMutation = useMutation(Queries.mutations.CREATE_TEXT);
  
  useEffect(() => {
    if(state?.id) {
        setWaiting(true)
        queryClient.fetchQuery(Queries.queries.TEXT(state.id,state.type)).then(data => {
          assignData(data)
          setWaiting(false)        
        }).catch(error => {
          console.error('onEditError',error)
        }).finally(()=>{
          setWaiting(false)
        })
    }
    else {
      setEditName(true)
    }
  },[])

  const createNewTextPayload = (value:{id?:string,data:{name:string,voice:string,style:string,language:string,type:any,data:any,testimonies:TESTIMONY[]}}) => {
    setWaiting(true)
    newTextMutation.mutate(value,
      {
      onSuccess: (data: any) => {
        assignData(data)
        setWaiting(false);
      },
      onError: (err) => {
        console.error(err)
        setWaiting(false);
      }
    });
  }

  const regenerate = () => {
    createNewTextPayload({id:id,data:{name,voice,style,type,data,testimonies,language}})
  }

  const nameSufix = editName ? 
    (
      <>
        <Button icon={<CloseOutlined/>} className="edit-name-icon" onClick={cancelEditName}/>
        <Button icon={<CheckOutlined/>} className="edit-name-icon" onClick={confirmEditName}/>

      </>
    )
    :(
      <Button icon={<EditOutlined />} onClick={startEditName} className="edit-name-icon" />
    )


  return (
    <>
    <Flex vertical style={{paddingLeft:'1rem',paddingRight:'1rem'}} gap="large" align="center" >
        <Flex justify="space-between" gap="large"  >
            <div className='create-text-type-label' onClick={()=>{if(prompt){setShowPrompts(true)}}}>
              {textType.title} 
            </div>
            <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Tema</span><QuestionPopup text="Nome interno para identificação.##Para uso em buscas e listas dentro da ferramenta." /></Flex>)} className="create-text-header-card create-text-header-card-theme"  >
              <Input id="nameEdit" value={name} onChange={handleNameChange} style={{width: 'calc(60ch)'}} readOnly={!editName} addonAfter={id === undefined ? undefined : nameSufix} />
            </Card>
            <Flex vertical>
              <Button className="briefing-button" style={{flex:'1'}} onClick={()=>setBriefingOpen(true)}>Briefing</Button>
            </Flex>
            <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Estilo</span></Flex>)} className="create-text-header-card"  >
                <TextStyle open={textTypeTourOpen} onClose={()=>setTextTypeTourOpen(false)} options="voice" value={voice} onChange={setVoice} />
              </Card>
              <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Tom de voz</span></Flex>)} className="create-text-header-card"  >
                <TextStyle open={voiceTourOpen} onClose={()=>setVoiceTourOpen(false)} options="tone" value={style} onChange={setStyle}/>
              </Card>
              {/* <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Língua</span></Flex>)} className="create-text-header-card"  >
                <TextStyle open={languageTourOpen} onClose={()=>setLanguageTourOpen(false)} options="language" value={language} onChange={setLanguage}/>
              </Card> */}
        </Flex>
        <Editor title={content?.title}  text={content?.content} id={id} type={type} onRegenerate={regenerate} canGenerate={createEnabled && (id === undefined || !editName)}/>
    </Flex>
    <Source type={textType.params.type} open={briefingOpen} onClose={()=>{setBriefingOpen(false)}} data={data!} onDataChanged={handleSetData} testimony={testimonies} onTestimonyChanged={setTestimonies} key="source1"/> 
    <Spin spinning={waiting} percent="auto" fullscreen />
    <PromptDialog open={showPrompts} onClose={()=>setShowPrompts(false)} prompts={prompt} />
    </>
  )
 
}

