import { useQuery } from "@tanstack/react-query"
import { Modal, Spin } from "antd"
import queries from './functions/Queries';

export default function HelloDisplay(props:{
  open:boolean,
  onClose:()=>void
}) {

  const { isPending, isError, data, error } = useQuery(queries.queries.HELLO)

  const content = isPending? <Spin size="large" /> : (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  )

  return (
    <Modal open={props.open} style={{ top: 20 }} width={1040} footer={null} onCancel={props.onClose}>
       {content}
    </Modal>
  )

}